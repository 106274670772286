
export default {
	props: {
		visibleTitle: {
            type: Boolean,
			default: true
		},
		forChoseModal: {
			type: Boolean,
			default: false
		},
		makesListItemClass: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ''
		},
		slug: {
			type: String,
			default: ''
		},
		image: {
			type: String,
			default: ''
		}
	}
}
