export const strict = false
import marks from '~/apollo/queries/marks'
import settings from '~/apollo/queries/settings'
import markFolderGeneration from '~/apollo/queries/markFolderGeneration'
import banks from "@/apollo/queries/bank/banks";

export const state = () => ({
  site_id: null,
  domain: '',
})
export const getters = {
  site_id: (state) => {
    return state.site_id
  },
  domain: (state) => {
    return state.domain
  },
  catalog_route: (state) => {
    return state.catalog_route
  },
}
export const actions = {
  async nuxtServerInit({ dispatch, commit, state }, { req, app, $config }) {

    commit('SET_SITE_ID', $config.site_id);
    commit('SET_DOMAIN', $config.domain);
    commit('SET_CATALOG_ROUTE', 'cars');

    let client = app.apolloProvider.defaultClient
    // TODO получаю дефолтный процент тут так как в state нет экземпляра контекста
    commit('banks/SET_PERCENT', $config.default_percent)
    // // TODO танцы с бубнами
    // commit('filters/filters/SET_LOADING_RANGE', false)

    if (req.headers.host === 'xn----7sbbgch0b6bl0a.xn--p1ai') {
      commit('SET_SITE_ID', $config.site_id_mirror1);
      commit('SET_DOMAIN', $config.domain_mirror1);
    }
    if (req.headers.host === 'autovyborg.ru') {
      commit('SET_SITE_ID', $config.site_id_mirror2);
      commit('SET_DOMAIN', $config.domain_mirror2);
    }
    if (req.headers.host === 'xn----7sbecbh7edso6h.xn--p1ai') {
      commit('SET_SITE_ID', $config.site_id_mirror3);
      commit('SET_DOMAIN', $config.domain_mirror3);
    }

    //TODO НАСТРОЙКИ САЙТА
    let currentSettings = {}
    let settingsResponse = await client.query(
      {
        query: settings,
        variables: { site_id: state.site_id }
      })
    settingsResponse.data.settings.settings.map(value => {
      currentSettings[value.key] = value.value
    })
    commit('settings/settings/SET_SETTINGS', currentSettings)

    //   commit('SET_DOMAIN', currentSettings.domain.slice(0, -1));



    // TODO ПОЛУЧАЮ СПИСОК МАРОК
    let response = await client.query(
      {
        query: marks,
        variables: { site_id: state.site_id, category: 'cars' }
      })

    commit('marks/marks/SET_ALL_MARKS', response.data.marks)


    let markFolderGenerationParam = await client.query(
      {
        query: markFolderGeneration,
        variables: {
          site_id: state.site_id,
        }
      })
    await commit('marks/marks/SET_MARK_FOLDER_GENERATION', markFolderGenerationParam.data.markFolderGeneration)

    await commit('popularMarks/popularMarks/SET_POPULAR_MARKS', markFolderGenerationParam.data.markFolderGeneration)


    let banksParam = await client.query(
      {
        query: banks,
        variables: {
          site_id: state.site_id,
        }
      })


    let rootApiLink = 'https://api.genzes.ru/'
    let localApiLink = `https://api.autodrive.spb.ru/`

    let banksList = banksParam.data.banks.map((bank) => {
      bank.image = bank.image.replace(rootApiLink, localApiLink)
      return bank;
    })
    // let banksList =banksParam.data.banks
    let arrayFiltered = ['Газпромбанк Автолизинг', 'Ингосстрах Банк', 'МКБ', 'Банк Авангард', 'Ренессанс Банк', 'Хоум Банк', 'Райффайзен Банк', 'Почта Банк']
    banksList = banksList.filter(el => {
      return !(arrayFiltered.includes(el.name))
    })

    let banksMain = banksList;
    for (let param of banksMain) {
      for (let el of state.banks.banksFiltered) {
        if (param.name == el.name) {
          param.rate = el.rate
          param.place = el.place
        }
      }
      if (!param.hasOwnProperty("place")) {
        param.place = 51
      }
    }
    banksMain = banksMain.sort((a, b) => a.place - b.place);

    await commit('banks/SET_BANKS', banksMain)

  },
  async request({ state }, { query, variables }) {
    let assignVariables = {
      site_id: state.site_id
    }
    let client = this.app.apolloProvider.defaultClient
    let params = { ...assignVariables, ...variables }
    return await client.query(
      {
        query: query,
        variables: this.$removeEmptyObjects(params),
        fetchPolicy: 'no-cache'
      })
  }
}
export const mutations = {
  SET_SITE_ID(state, data) {
    state.site_id = data
  },
  SET_DOMAIN(state, data) {
    state.domain = data
  },
  SET_CATALOG_ROUTE(state, data) {
    state.catalog_route = data
  },
}
