
import AOS from "aos"
import "aos/dist/aos.css"
import { mapGetters } from "vuex"

export default {
  mounted() {
    AOS.init({
      once: true
    });
    const sliderIndexSwiper = new swiper.default(".slider-index .swiper", {
      modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
      loop: true,
      autoplayDisableOnInteraction: false,
      autoplay: true,
      slidesPerView: 1,
      autoHeight: true,
      watchSlidesProgress: true,
      scrollbar: false,
      disableOnInteraction: false,
      speed: 2000,
      autoplay: {
        delay: 3800
      },
      on: {
        sliderMove: this.sliderMove,
        slideChangeTransitionStart: this.slideChangeTransitionStart
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: "true",
        type: "bullets",
        renderBullet: function(index, className) {
          return (
            '<span class="' +
            className +
            '">' +
            '<span class="swiper-pagination-bar"></span>' +
            '<span class="swiper-pagination-progress"></span>' +
            "</span>"
          );
        }
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  },
  data() {
    return {
      monthArray: [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря"
      ],
      currentMount: new Date().getMonth()
    };
  },
  methods: {
    sliderMove() {
      document
        .querySelectorAll(".swiper-slide-next .slider-index__media")
        .forEach(val => {});
      document
        .querySelectorAll(".swiper-slide-next .slider-index__circle")
        .forEach(val => {
          val.style.display = "none";
          val.classList.remove("aos-init", "aos-animate");
          val.style.display = "block";
          val.classList.add("aos-init", "aos-animate");
        });
    }
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings"
    }),
    slides() {
      return [
        // {
        //   id: 4,
        //   heading: `Обновите авто<br/>в Новый год`,
        //   content: `Выгода за Trade-In<br/>
        //   ${this.settings.sale_tradein} ₽`,
        //   link: "/exchange"
        // },
        {
          id: 2,
          heading: `Оформи автокредит от ${this.settings.credit_percent} <br> со скидкой ${this.settings.sale_credit} ₽`,
          content: "",
          link: "/credit"
        },
        {
          id: 8,
          heading: `Trade-in с выгодой <br>  ${this.settings.sale_tradein} ₽`,
          content: "",
          link: "/exchange"
        },
        {
          id: 5,
          heading: "Более 1000 проверенных автомобилей в наличии",
          content: "",
          link: "/cars"
        },
        {
          id: 1,
          heading: `Сюрприз-бонус от менеджера при покупке в кредит`,
          content: "",
          link: "/cars"
        },
        {
          id: 3,
          heading: `Рассрочка на китайские марки 0% до конца ${
            this.monthArray[this.currentMount]
          }`,
          content: "",
          link: "/cars"
        }
        // {
        // 	id: 7,
        // 	heading: ' Читайте наш Telegram-канал',
        // 	content: 'Последние поступления, акции, новости',
        // 	telegram: true
        // },

        // {
        // 	id: 2,
        // 	heading: 'Автокредит ВТБ',
        // 	content: 'Лучшие условия для клиентов carro.ru',
        // 	link: '/credit/vtb'
        // },
        // {
        // 	id: 3,
        // 	heading: 'Рассрочка от Сбера и Сетелем',
        // 	content: 'Лучшие условия для клиентов carro.ru',
        // 	link: '/rassrochka'
        // },
        // {
        // 	id: 4,
        // 	heading: 'Заморозили цены и ставки',
        // 	content: 'Успей купить автомобиль по старой цене',
        // 	link: '/credit'
        // },
        // {
        // 	id: 5,
        // 	heading: '2-й комплект резины в подарок',
        // 	content: 'При покупке автомобиля в кредит',
        // 	link: '/credit'
        // }
      ];
    }
  }
};
